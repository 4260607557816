.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #dbdbdb;
}

.link {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}
.link a {
  text-decoration: none;
  font-weight: 500;
}

.menuIcon {
  display: none;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
  cursor: pointer;
}

.menuIcon button {
  border: none;
  background: transparent;
}

.logo {
  height: 60px;
  margin-top: 10px;
  margin-left: 40px;
}

.toolbar {
  height: 80px;
  padding: 0px !important;
  font-size: 1.1em;
}

.subPages {
  margin-right: 100px;
}

.link > a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -5px;
  left: 0;
  background-color: #c3666f;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.link > a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.closeIcon {
  display: none;
  background: #434343;
  color: white;
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.closeIcon button {
  background: #434343;
  border: none;
  color: white;
}

@media only screen and (max-width: 1200px) {
  .toolbar {
    height: inherit;
  }
  .subPages {
    display: none;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
  }

  .menuIcon {
    display: block;
  }
}

.mobileMenu,
.mobileMenu:first-child {
  display: inherit;
  height: 100vh;
  width: 100vw;
  background-color: #434343;
  color: white;
  overflow: hidden;
}

.mobileMenu .content {
  justify-content: start;
  border: none;
}

.mobileMenu .subPages {
  display: inherit;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 20px;
}

.mobileMenu .logo {
  display: none;
}

.mobileMenu a {
  color: white;
}

.mobileMenu .link {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mobileMenu .closeIcon {
  display: block;
}

.mobileMenu .menuIcon {
  display: none;
}
