.footer {
  display: flex;
  flex-wrap: wrap;
  background-color: #434343;
  justify-content: space-between;
  color: white;
  min-height: 300px;
  height: 100%;
  font-weight: 400;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 200px;
}

.footer h1 {
  font-weight: 400;
  font-size: 35px;
}

.footer p,
footer a {
  color: white;
  margin: 10px;
  margin-left: 0px;
}

.contact {
  display: flex;
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
}

.contact div {
  margin-left: 30px;
}

.subtitle {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

@media only screen and (max-width: 800px) {
  .contact div {
    margin-left: 0px;
  }

  .contact {
    margin-top: 20px;
  }

  .footer {
    padding-bottom: 100px;
  }
}
