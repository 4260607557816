.container {
  padding: 0 15px;
  margin: 0 auto;
  margin-top: 100px;
}
.largeContainer {
  padding: 0 15px;
  margin: 0 auto;
  margin-top: 100px;
}

@media (min-width: 620px) {
  .largeContainer {
    width: 600px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
  .largeContainer {
    width: 700px;
  }
}
