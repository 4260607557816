.link {
  color: black;
  text-decoration: none;
  margin-right: 1%;
}

.card {
  margin: 25px;
  font-family: 'PT Serif', serif;
  color: #424344;
}

.card .imageWrapper {
  height: 300px;
  width: 300px;
  overflow: hidden;
  position: relative;
}

.card .imageWrapper img {
  height: 100%;
  transition: 0.3s;
}

.card .arrowIcon {
  position: relative;
  transition: 0.3s;
  font-size: 2em;
}

.card:hover img {
  transform: scale(1.1);
}

.card:hover .arrowIcon {
  transform: translateX(50px);
}

@media (min-width: 768px) {
  .link {
    width: 49%;
  }
}

@media (min-width: 1200px) {
  .link {
    width: 32%;
  }
}
