.img {
  max-width: 70%;
}

@media only screen and (max-width: 800px) {
  .img {
    height: auto;
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
}
