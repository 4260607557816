body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'PT Serif', serif;
  background: #f2f2f2;
  color: #434343;
}

.mobileMenuMode {
  position: fixed;
  overflow: hidden;
}

button,
a {
  color: #434343;
  font-family: 'Roboto', sans-serif;
}

.MuiIcon-fontSizeLarge-13 {
  font-size: 45px;
}

@media only screen and (min-width: 1200px) {
  #container {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.Footer_footer__3uptT {
  display: flex;
  flex-wrap: wrap;
  background-color: #434343;
  justify-content: space-between;
  color: white;
  min-height: 300px;
  height: 100%;
  font-weight: 400;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 200px;
}

.Footer_footer__3uptT h1 {
  font-weight: 400;
  font-size: 35px;
}

.Footer_footer__3uptT p,
footer a {
  color: white;
  margin: 10px;
  margin-left: 0px;
}

.Footer_contact__Z4FNb {
  display: flex;
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
}

.Footer_contact__Z4FNb div {
  margin-left: 30px;
}

.Footer_subtitle__2OJDw {
  font-weight: 700;
}

.Footer_underline__rU_3i {
  text-decoration: underline;
}

@media only screen and (max-width: 800px) {
  .Footer_contact__Z4FNb div {
    margin-left: 0px;
  }

  .Footer_contact__Z4FNb {
    margin-top: 20px;
  }

  .Footer_footer__3uptT {
    padding-bottom: 100px;
  }
}

.CustomMessage_customMessage__aETLw {
  background-color: #838282;
  justify-content: space-between;
  color: white;
  text-align: center;
}

.CustomMessage_customMessage__aETLw h3,
.CustomMessage_h3__nre4R {
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
}

.NavBar_content__1yv_x {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #dbdbdb;
}

.NavBar_link__2lBLO {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}
.NavBar_link__2lBLO a {
  text-decoration: none;
  font-weight: 500;
}

.NavBar_menuIcon__1tsCq {
  display: none;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
  cursor: pointer;
}

.NavBar_menuIcon__1tsCq button {
  border: none;
  background: transparent;
}

.NavBar_logo__mvXIC {
  height: 60px;
  margin-top: 10px;
  margin-left: 40px;
}

.NavBar_toolbar__D2kdM {
  height: 80px;
  padding: 0px !important;
  font-size: 1.1em;
}

.NavBar_subPages__3TB0F {
  margin-right: 100px;
}

.NavBar_link__2lBLO > a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -5px;
  left: 0;
  background-color: #c3666f;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.NavBar_link__2lBLO > a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.NavBar_closeIcon__fIyYA {
  display: none;
  background: #434343;
  color: white;
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.NavBar_closeIcon__fIyYA button {
  background: #434343;
  border: none;
  color: white;
}

@media only screen and (max-width: 1200px) {
  .NavBar_toolbar__D2kdM {
    height: inherit;
  }
  .NavBar_subPages__3TB0F {
    display: none;
  }
  .NavBar_logo__mvXIC {
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
  }

  .NavBar_menuIcon__1tsCq {
    display: block;
  }
}

.NavBar_mobileMenu__1x1vp,
.NavBar_mobileMenu__1x1vp:first-child {
  display: inherit;
  height: 100vh;
  width: 100vw;
  background-color: #434343;
  color: white;
  overflow: hidden;
}

.NavBar_mobileMenu__1x1vp .NavBar_content__1yv_x {
  justify-content: start;
  border: none;
}

.NavBar_mobileMenu__1x1vp .NavBar_subPages__3TB0F {
  display: inherit;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 20px;
}

.NavBar_mobileMenu__1x1vp .NavBar_logo__mvXIC {
  display: none;
}

.NavBar_mobileMenu__1x1vp a {
  color: white;
}

.NavBar_mobileMenu__1x1vp .NavBar_link__2lBLO {
  margin-top: 15px;
  margin-bottom: 15px;
}

.NavBar_mobileMenu__1x1vp .NavBar_closeIcon__fIyYA {
  display: block;
}

.NavBar_mobileMenu__1x1vp .NavBar_menuIcon__1tsCq {
  display: none;
}

.styles_img__7Prg2 {
  max-width: 70%;
}

@media only screen and (max-width: 800px) {
  .styles_img__7Prg2 {
    height: auto;
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
}

.Property_grid__2q-30 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.Property_link__29-7p {
  color: black;
  text-decoration: none;
  margin-right: 1%;
}

.Property_propertyFilter__3r96- {
  margin-top: 80px;
  margin-bottom: 80px;
}

.Property_propertyFilter__3r96- .Property_areaInput__2chHR {
  width: 200px;
}

.Property_property__1hbWl {
  font-family: 'PT Serif', serif;
  color: #424344;
  margin-bottom: 40px;
}

.Property_property__1hbWl .Property_shortDescription__1ukgZ p {
  margin-bottom: 0;
}

.Property_property__1hbWl .Property_imageWrapper__1R9vK {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.Property_property__1hbWl .Property_imageWrapper__1R9vK img {
  width: 100%;
  transition: 0.3s;
}

.Property_property__1hbWl .Property_arrowIcon__3q-js {
  position: relative;
  transition: 0.3s;
  font-size: 2em;
}

.Property_property__1hbWl:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Property_property__1hbWl:hover .Property_arrowIcon__3q-js {
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
}

@media (min-width: 768px) {
  .Property_link__29-7p {
    width: 49%;
  }
}

@media (min-width: 1200px) {
  .Property_link__29-7p {
    width: 32%;
  }
}

.AvailableHousingStyle_finnIframe__3ffSk {
  margin-top: 2px;
}

.AvailableHousingStyle_finnWrapper__1Fw1S {
  width: 100%;
  margin: 0 auto;
}

/* example code */
@media (min-width: 1010px) {
  .AvailableHousingStyle_finnWrapper__1Fw1S {
    width: 1010px;
  }
}

.Contact_header__2Wns6 {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  flex-wrap: wrap;
}

.Contact_header__2Wns6 h1 {
  font-size: 28px;
}

.Contact_contact__2_HlH {
  display: flex;
  flex-wrap: wrap;
}

.Contact_contact__2_HlH > div {
  margin-left: 70px;
}

.Contact_contact__2_HlH h2 {
  margin-top: 35px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

.Contact_contact__2_HlH p {
  margin-top: 4px;
  margin-bottom: 4px;
}

.Contact_grid__2ru90 {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.EmployeeCard_card__2gX9l {
  display: flex;
  flex-direction: column;
  margin: 40px;
  font-family: 'Roboto', sans-serif;
}

.EmployeeCard_card__2gX9l h2 {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0px;
}

.EmployeeCard_card__2gX9l h3 {
  margin-top: 5px;
  font-weight: 100;
  font-size: 16px;
}

.EmployeeCard_card__2gX9l a {
  font-weight: 100;
  margin-top: 5px;
}

.EmployeeCard_card__2gX9l img {
  width: 300px;
  height: 300px;
  background-color: gray;
}

.Card_link__3JA8P {
  color: black;
  text-decoration: none;
  margin-right: 1%;
}

.Card_card__3UiEP {
  margin: 25px;
  font-family: 'PT Serif', serif;
  color: #424344;
}

.Card_card__3UiEP .Card_imageWrapper__2lMI3 {
  height: 300px;
  width: 300px;
  overflow: hidden;
  position: relative;
}

.Card_card__3UiEP .Card_imageWrapper__2lMI3 img {
  height: 100%;
  transition: 0.3s;
}

.Card_card__3UiEP .Card_arrowIcon__2sXGD {
  position: relative;
  transition: 0.3s;
  font-size: 2em;
}

.Card_card__3UiEP:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Card_card__3UiEP:hover .Card_arrowIcon__2sXGD {
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
}

@media (min-width: 768px) {
  .Card_link__3JA8P {
    width: 49%;
  }
}

@media (min-width: 1200px) {
  .Card_link__3JA8P {
    width: 32%;
  }
}

.ForTenants_header__1S93P {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  flex-wrap: wrap;
}

.ForTenants_header__1S93P h1 {
  font-size: 28px;
}

.ForTenants_contact__373Gq {
  display: flex;
  flex-wrap: wrap;
}

.ForTenants_contact__373Gq > div {
  margin-left: 20px;
}

.ForTenants_contact__373Gq h2 {
  margin-top: 35px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

.ForTenants_contact__373Gq p {
  margin-top: 4px;
  margin-bottom: 4px;
}

.ForTenants_grid__2D8i_ {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;
}

.ForTenants_emptyCard__1Udus {
  width: 300px;
  margin: 25px;
}

.style_fadeIn__3OVAa {
  display: flex;
  width: 100%;
  -webkit-transform: translateX(-40px);
          transform: translateX(-40px);
  justify-content: center;
  align-items: center;
}
.style_fadeInLeft__26ned {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.style_fadeInRight__3K_Xn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
}

.style_img__UsoYl {
  height: auto;
  width: auto;
  max-height: 65vh;
  max-width: 60vw;
  object-fit: scale-down;
}

.style_img2__3c8_b {
  height: auto;
  width: auto;
  max-height: 65vh;
  max-width: 30vw;
  object-fit: scale-down;
}

.style_img3__19LWC {
  height: auto;
  width: auto;
  max-height: 50vh;
  max-width: 25vw;
  object-fit: scale-down;
}

.style_headerText__KP3Ql {
  -webkit-transform: translateX(80px);
          transform: translateX(80px);
  top: -70px;
  position: relative;
}

.style_headerTextBackground__1jNlx {
  background-color: #f2f2f2;
  margin: 0px;
  padding: 10px;
}

.style_headerTextNoBackground__2QKuJ {
  margin: 0px;
  padding: 10px;
  padding-top: 0px;
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}

.style_sectionText__2HhXb {
  font-weight: 300;
  color: #423c29;
  max-width: 400px;
  margin: 60px;
  margin-top: 10px;
  font-weight: 100px;
  font-size: 34px;
}

.style_sectionText__2HhXb h1 {
  font-size: 34px;
  font-weight: 100;
}

.style_sectionText__2HhXb h2 {
  font-size: 20px;
  font-weight: 100;
}

.style_sectionText__2HhXb a {
  font-size: 16px;
  font-weight: bold;
  top: -20px;
  position: relative;
}

.style_sectionText__2HhXb span {
  height: 16px;
}
.style_subSections__2xUQ5 {
  width: 75%;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .style_img__UsoYl {
    height: auto;
    width: 90%;
    max-width: 90%;
    margin: auto;
    -webkit-transform: inherit;
            transform: inherit;
  }

  .style_fadeIn__3OVAa {
    position: relative;
    -webkit-transform: inherit;
            transform: inherit;
  }
  .style_headerText__KP3Ql {
    position: absolute;
    bottom: 0px;
    left: 5%;
    top: inherit;
    -webkit-transform: inherit;
            transform: inherit;
  }

  .style_headerTextBackground__1jNlx {
    font-size: 4.3vw;
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }

  .style_headerTextNoBackground__2QKuJ {
    background-color: #f2f2f2;
    font-size: 4.3vw;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  .style_fadeInLeft__26ned {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 70vw;
    margin-left: 20vw;
  }

  .style_fadeInLeft__26ned img {
    max-height: 50vh;
    max-width: 50vw;
  }

  .style_sectionText__2HhXb {
    margin: 0px;
  }

  .style_sectionText__2HhXb h1 {
    font-size: 5vw;
  }

  .style_sectionText__2HhXb h2 {
    font-size: 3.5vw;
  }

  .style_fadeInRight__3K_Xn {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 70vw;
    margin-right: 20vw;
  }

  .style_fadeInRight__3K_Xn img {
    max-height: 50vh;
    max-width: 50vw;
  }

  .style_fadeInRight__3K_Xn img {
    order: -1;
  }
}

.style_section__1ri-2 {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}

.PropertyStyles_header__1BwHX {
  color: #0b1648;
}

.PropertyStyles_address__394KO {
  display: flex;
  align-items: baseline;
}

.PropertyStyles_address__394KO h2 {
  margin-left: 20px;
}

@media only screen and (max-width: 700px) {
  .PropertyStyles_address__394KO {
    display: inline;
  }
  .PropertyStyles_address__394KO h2 {
    margin-left: 0px;
  }
}

.PropertyStyles_carouselItem__34aTA {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #313233;
}

.mapstyle_relativeMap__1b98f {
  position: relative;
}

.mapstyle_smallMap__1Uxky {
  position: relative;
  width: 100%;
  height: 98vw;
  margin-top: 30px;
  margin-bottom: 100px;
  box-shadow: 0px 2px 5px -1px rgba(135, 135, 135, 1);
}

@media (min-width: 768px) {
  .mapstyle_smallMap__1Uxky {
    width: 40vw;
    height: 40vw;
  }
}

@media (min-width: 1200px) {
  .mapstyle_smallMap__1Uxky {
    width: 25vw;
    height: 25vw;
  }
}

.ReportError_content__3ua3A {
  width: 100%;
  display: flex;
  margin: 0 auto;
}

.ReportError_header__2yeEr {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
}

.ReportError_header__2yeEr h1 {
  font-size: 28px;
}

.ReportError_header__2yeEr h2 {
  font-size: 20px;
  margin-top: -24px;
}

.ReportError_contact__1sn7h {
  margin: 0 auto;
}

.ReportError_contact__1sn7h h2 {
  margin-top: 35px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

.ReportError_contact__1sn7h p {
  margin-top: 4px;
  margin-bottom: 4px;
}

.ReportError_formRow__zyRk5 {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 80%;
  max-width: 670px;
}

.ReportError_input__1Jsj0 {
  width: 96.5%;
  padding: 8px;
  border: 0.1px solid #b3b3b3;
  border-radius: 3px;
}

.ReportError_textfield__2tuZ6 {
  width: 96.5%;
  padding: 8px;
  border: 0.1px solid #b3b3b3;
  border-radius: 3px;
  min-height: 80px;
  resize: none;
}

.ReportError_fileBtn__1POI- {
  border: 1px solid #b3b3b3;
  padding: 4px;
  background-color: white;
  border-radius: 3px;
}

.ReportError_submitBtn__3B--8 {
  border: 1px solid #b3b3b3;
  padding: 8px;
  background-color: #434343;
  border-radius: 3px;
  width: 100%;
  color: white;
  cursor: pointer;
}

.ReportError_submitBtnDisabled__3TN2R {
  border: 1px solid #b3b3b3;
  padding: 8px;
  border-radius: 3px;
  width: 100%;
  color: #656565;
  background-color: #434343;
  color: white;
  cursor: not-allowed;
}

.ReportError_submittedText__Gg7cd {
  color: green;
}

.layout_container__3bDJo {
  padding: 0 15px;
  margin: 0 auto;
  margin-top: 100px;
}
.layout_largeContainer__3C3Ez {
  padding: 0 15px;
  margin: 0 auto;
  margin-top: 100px;
}

@media (min-width: 620px) {
  .layout_largeContainer__3C3Ez {
    width: 600px;
  }
}
@media (min-width: 768px) {
  .layout_container__3bDJo {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .layout_container__3bDJo {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .layout_container__3bDJo {
    width: 1170px;
  }
  .layout_largeContainer__3C3Ez {
    width: 700px;
  }
}

