.content {
  width: 100%;
  display: flex;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
}

.header h1 {
  font-size: 28px;
}

.header h2 {
  font-size: 20px;
  margin-top: -24px;
}

.contact {
  margin: 0 auto;
}

.contact h2 {
  margin-top: 35px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

.contact p {
  margin-top: 4px;
  margin-bottom: 4px;
}

.formRow {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 80%;
  max-width: 670px;
}

.input {
  width: 96.5%;
  padding: 8px;
  border: 0.1px solid #b3b3b3;
  border-radius: 3px;
}

.textfield {
  width: 96.5%;
  padding: 8px;
  border: 0.1px solid #b3b3b3;
  border-radius: 3px;
  min-height: 80px;
  resize: none;
}

.fileBtn {
  border: 1px solid #b3b3b3;
  padding: 4px;
  background-color: white;
  border-radius: 3px;
}

.submitBtn {
  border: 1px solid #b3b3b3;
  padding: 8px;
  background-color: #434343;
  border-radius: 3px;
  width: 100%;
  color: white;
  cursor: pointer;
}

.submitBtnDisabled {
  border: 1px solid #b3b3b3;
  padding: 8px;
  border-radius: 3px;
  width: 100%;
  color: #656565;
  background-color: #434343;
  color: white;
  cursor: not-allowed;
}

.submittedText {
  color: green;
}
