.header {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  flex-wrap: wrap;
}

.header h1 {
  font-size: 28px;
}

.contact {
  display: flex;
  flex-wrap: wrap;
}

.contact > div {
  margin-left: 70px;
}

.contact h2 {
  margin-top: 35px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

.contact p {
  margin-top: 4px;
  margin-bottom: 4px;
}

.grid {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
