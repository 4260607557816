body {
  font-family: 'PT Serif', serif;
  background: #f2f2f2;
  color: #434343;
}

.mobileMenuMode {
  position: fixed;
  overflow: hidden;
}

button,
a {
  color: #434343;
  font-family: 'Roboto', sans-serif;
}

.MuiIcon-fontSizeLarge-13 {
  font-size: 45px;
}

@media only screen and (min-width: 1200px) {
  #container {
    margin-left: 5%;
    margin-right: 5%;
  }
}
