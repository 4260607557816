.grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.link {
  color: black;
  text-decoration: none;
  margin-right: 1%;
}

.propertyFilter {
  margin-top: 80px;
  margin-bottom: 80px;
}

.propertyFilter .areaInput {
  width: 200px;
}

.property {
  font-family: 'PT Serif', serif;
  color: #424344;
  margin-bottom: 40px;
}

.property .shortDescription p {
  margin-bottom: 0;
}

.property .imageWrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.property .imageWrapper img {
  width: 100%;
  transition: 0.3s;
}

.property .arrowIcon {
  position: relative;
  transition: 0.3s;
  font-size: 2em;
}

.property:hover img {
  transform: scale(1.1);
}

.property:hover .arrowIcon {
  transform: translateX(50px);
}

@media (min-width: 768px) {
  .link {
    width: 49%;
  }
}

@media (min-width: 1200px) {
  .link {
    width: 32%;
  }
}
