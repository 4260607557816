.header {
  color: #0b1648;
}

.address {
  display: flex;
  align-items: baseline;
}

.address h2 {
  margin-left: 20px;
}

@media only screen and (max-width: 700px) {
  .address {
    display: inline;
  }
  .address h2 {
    margin-left: 0px;
  }
}

.carouselItem {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #313233;
}
