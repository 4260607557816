.fadeIn {
  display: flex;
  width: 100%;
  transform: translateX(-40px);
  justify-content: center;
  align-items: center;
}
.fadeInLeft {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.fadeInRight {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
}

.img {
  height: auto;
  width: auto;
  max-height: 65vh;
  max-width: 60vw;
  object-fit: scale-down;
}

.img2 {
  height: auto;
  width: auto;
  max-height: 65vh;
  max-width: 30vw;
  object-fit: scale-down;
}

.img3 {
  height: auto;
  width: auto;
  max-height: 50vh;
  max-width: 25vw;
  object-fit: scale-down;
}

.headerText {
  transform: translateX(80px);
  top: -70px;
  position: relative;
}

.headerTextBackground {
  background-color: #f2f2f2;
  margin: 0px;
  padding: 10px;
}

.headerTextNoBackground {
  margin: 0px;
  padding: 10px;
  padding-top: 0px;
  transform: translateY(-7px);
}

.sectionText {
  font-weight: 300;
  color: #423c29;
  max-width: 400px;
  margin: 60px;
  margin-top: 10px;
  font-weight: 100px;
  font-size: 34px;
}

.sectionText h1 {
  font-size: 34px;
  font-weight: 100;
}

.sectionText h2 {
  font-size: 20px;
  font-weight: 100;
}

.sectionText a {
  font-size: 16px;
  font-weight: bold;
  top: -20px;
  position: relative;
}

.sectionText span {
  height: 16px;
}
.subSections {
  width: 75%;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .img {
    height: auto;
    width: 90%;
    max-width: 90%;
    margin: auto;
    transform: inherit;
  }

  .fadeIn {
    position: relative;
    transform: inherit;
  }
  .headerText {
    position: absolute;
    bottom: 0px;
    left: 5%;
    top: inherit;
    transform: inherit;
  }

  .headerTextBackground {
    font-size: 4.3vw;
    transform: translateY(8px);
  }

  .headerTextNoBackground {
    background-color: #f2f2f2;
    font-size: 4.3vw;
    transform: translateY(0px);
  }

  .fadeInLeft {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 70vw;
    margin-left: 20vw;
  }

  .fadeInLeft img {
    max-height: 50vh;
    max-width: 50vw;
  }

  .sectionText {
    margin: 0px;
  }

  .sectionText h1 {
    font-size: 5vw;
  }

  .sectionText h2 {
    font-size: 3.5vw;
  }

  .fadeInRight {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 70vw;
    margin-right: 20vw;
  }

  .fadeInRight img {
    max-height: 50vh;
    max-width: 50vw;
  }

  .fadeInRight img {
    order: -1;
  }
}
