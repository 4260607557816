.card {
  display: flex;
  flex-direction: column;
  margin: 40px;
  font-family: 'Roboto', sans-serif;
}

.card h2 {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0px;
}

.card h3 {
  margin-top: 5px;
  font-weight: 100;
  font-size: 16px;
}

.card a {
  font-weight: 100;
  margin-top: 5px;
}

.card img {
  width: 300px;
  height: 300px;
  background-color: gray;
}
