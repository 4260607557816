.finnIframe {
  margin-top: 2px;
}

.finnWrapper {
  width: 100%;
  margin: 0 auto;
}

/* example code */
@media (min-width: 1010px) {
  .finnWrapper {
    width: 1010px;
  }
}
