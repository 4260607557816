.relativeMap {
  position: relative;
}

.smallMap {
  position: relative;
  width: 100%;
  height: 98vw;
  margin-top: 30px;
  margin-bottom: 100px;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(135, 135, 135, 1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(135, 135, 135, 1);
  box-shadow: 0px 2px 5px -1px rgba(135, 135, 135, 1);
}

@media (min-width: 768px) {
  .smallMap {
    width: 40vw;
    height: 40vw;
  }
}

@media (min-width: 1200px) {
  .smallMap {
    width: 25vw;
    height: 25vw;
  }
}
